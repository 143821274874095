import React from "react"

interface Props {}

const IconCross = (props: Props) => {
  return (
    <svg width={18.525} height={18.914} viewBox="0 0 18.525 18.914" {...props}>
      <g
        data-name="Group 178"
        transform="translate(-1743.793 -673.293)"
        fill="none"
        stroke="#fff8ef"
        strokeWidth={2}
      >
        <path
          data-name="Line 77"
          transform="translate(1744.5 674.389)"
          d="M0 0L17.111 17.111"
        />
        <path data-name="Path 39" d="M1761.611 674l-17 17" />
      </g>
    </svg>
  )
}

export default IconCross
