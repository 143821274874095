import React, { useReducer, useState } from "react"
import BgImage2 from "../../images/img_amenidades/jacuzzi-01.jpg"
import BgImage1 from "../../images/img_m04/m04-01.jpg"
import BgImage from "../../images/img_amenidades/salon-01.jpg"
import { reducer } from "../../store/reducers"
import Button from "../Button"
import Modal from "../Modal"
import SliderGallery from "../SliderGallery"

interface Props {}
const imagesHome = {
  imgSelected: 1,
  options: [{ img: BgImage }, { img: BgImage1 }, { img: BgImage2 }],
}
const Home = ({ data }) => {
  const { node } = data
  const { imagenes } = node

  const imgGallery = imagenes.map(({ file }) => ({
    img: file.url,
  }))

  const [showVideo, setshowVideo] = useState(false)
  const [state, dispatch] = useReducer(reducer, imagesHome)
  return (
    <section className="home-section">
      <div className="home-img">
        <SliderGallery auto className="image" images={imgGallery} />
      </div>
      <aside className="home-aside">
        <div className="line line-no-m"></div>
        <div className="text">
          <h2 className="home-title">{node?.titulo}</h2>
          <p className="home-text">{node?.texto}</p>
        </div>
        <div className="line"></div>
        <Button border onClick={() => setshowVideo(true)}>
          REPRODUCIR VIDEO
        </Button>
      </aside>
      <Modal showModal={showVideo} closeModal={() => setshowVideo(false)}>
        <div className="video">
          <iframe
            width="560"
            height="315"
            src={node?.videoUrl}
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </Modal>
    </section>
  )
}

export default Home
