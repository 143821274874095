import React from "react"

const IconBt = (props: React.ElementType<"svg">) => {
  return (
    <svg viewBox="0 0 52.13 91.54" width={16} height={16} {...props}>
      <defs>
        <style>{".cls-1{fill:#d3b29d}"}</style>
      </defs>
      <g id="Capa_2" data-name="Capa 2">
        <g id="curvas">
          <g id="Group_354" data-name="Group 354">
            <path
              id="Path_142"
              data-name="Path 142"
              className="cls-1"
              d="M36.79 65l-6.32-6.32v12.61zm-6.74-10.82L39.82 64a1.44 1.44 0 010 2l-9.77 9.77a1.44 1.44 0 01-2 0 1.41 1.41 0 01-.42-1V55.19a1.44 1.44 0 011.45-1.42 1.42 1.42 0 011 .41z"
            />
            <path
              id="Path_143"
              data-name="Path 143"
              className="cls-1"
              d="M30.47 32.89l6.32-6.32-6.32-6.32v12.64zm9.35-5.31l-9.77 9.77a1.42 1.42 0 01-2.44-1V16.8a1.42 1.42 0 012.42-1l9.77 9.76a1.44 1.44 0 010 2z"
            />
            <g id="Group_353" data-name="Group 353">
              <path
                id="Path_144"
                data-name="Path 144"
                className="cls-1"
                d="M10.61 24.42a4.52 4.52 0 00-6.4 0 4.52 4.52 0 000 6.4l13.94 13.94a1.42 1.42 0 010 2L4.21 60.71a4.54 4.54 0 000 6.4 4.52 4.52 0 006.4 0l8-8a1.44 1.44 0 012 0 1.47 1.47 0 01.4 1v24a4.43 4.43 0 001.33 3.2 4.52 4.52 0 006.37 0l19.18-19.09a4.52 4.52 0 000-6.37l-15-15a1.44 1.44 0 010-2l15-15a4.52 4.52 0 000-6.37L28.73 4.19a4.53 4.53 0 00-6.38 0A4.51 4.51 0 0021 7.39v24a1.43 1.43 0 01-1.43 1.43 1.41 1.41 0 01-1-.43l-8-8zm-3.2-4.18a7.3 7.3 0 015.22 2.16l5.54 5.54V7.38a7.48 7.48 0 012.17-5.22 7.39 7.39 0 0110.44 0L49.92 21.3a7.39 7.39 0 010 10.44L36 45.75l13.94 14a7.38 7.38 0 010 10.43L30.8 89.34a7.38 7.38 0 01-10.45 0 7.46 7.46 0 01-2.17-5.22V63.59l-5.54 5.54a7.38 7.38 0 01-10.45 0 7.4 7.4 0 010-10.45l12.9-12.92L2.16 32.83a7.4 7.4 0 010-10.45 7.43 7.43 0 015.23-2.16z"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default IconBt
