import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import React, { useEffect, useReducer, useState } from "react"
import {
  ContenfulSeccion,
  formatDataStore,
  reducer,
} from "../../store/reducers"
import Button from "../Button"
import ButtonLinkHeader from "../ButtonLinkHeader"
import LeftArrow from "../LeftArrow"
import LogoCumbres from "../LogoCumbres"
import Modal from "../Modal"
import RightArrow from "../RightArrow"
import ShowResponsiveMessage from "../ShowResponsiveMessage"
import Slider from "../Slider"
import SliderGallery from "../SliderGallery"

interface Props {
  data: ContenfulSeccion[]
}

const Testimonials = (props: Props) => {
  const [state, dispatch] = useReducer(reducer, formatDataStore(props.data[1]))
  const changeOptionGallery = index => {
    dispatch({ type: "CHANGE", payload: index })
  }
  const changeOptionGalleryImg = index => {
    dispatch({ type: "CHANGE_IMG", payload: index })
  }

  const clickRight = () => {
    const toPage =
      state.selected + 1 < state.options.length
        ? state.selected + 1
        : state.selected
    changeOptionGallery(toPage)
  }
  const clickLeft = () => {
    const toPage = state.selected - 1 >= 0 ? state.selected - 1 : state.selected
    changeOptionGallery(toPage)
  }
  const [openModal, setOpenModal] = useState(false)
  const showTestimonials = () => setOpenModal(true)
  const hideTestimonials = () => setOpenModal(false)
  useEffect(() => {
    if (openModal) {
      document.body.style.position = "fixed"
      document.body.style.top = `-${window.scrollY}px`
    }
    return () => {
      document.body.style.position = ""
      document.body.style.top = ""
    }
  }, [openModal])
  const SliderGalleryList = state.options.map(({ img, id }, index) => (
    <div
      className={`slider ${index === state.selected ? "show" : "hide"}`}
      key={id}
    >
      <SliderGallery
        onChange={changeOptionGalleryImg}
        page={state.imgSelected}
        images={img}
      />
    </div>
  ))
  const detailsList = props.data[0].galeriaDeImagenes.map(item => {
    return (
      <div className="details">
        <h4 className="subtitle">{item.titulo}</h4>
        {item.listaDeDetalles.map(detail => (
          <p>{detail}</p>
        ))}
      </div>
    )
  })
  return (
    <section className="testimonial__section">
      <div className="testimonial__content">
        <div className="testimonial__content--header">
          <h2 className="title">{props.data[0].titulo}</h2>
        </div>
        <div className="testimonial__content--details">
          {detailsList}
          <Slider className="slider">{detailsList}</Slider>
        </div>
        <ShowResponsiveMessage className="text-green" drag={1} />
        <div className="line bg-green"></div>
        <div className="testimonial__content--footer">
          <Button onClick={showTestimonials}>
            LO QUE NUESTROS CLIENTES PIENSAN
          </Button>
        </div>
      </div>
      <Modal showModal={openModal} closeModal={hideTestimonials}>
        <section className="testimonial__grid">
          <div className="testimonial__grid--text">
            {documentToReactComponents(state.options[state.selected].text.json)}
          </div>
          <div className="testimonial__grid--images">{SliderGalleryList}</div>
          <div className="testimonial__grid--footer">
            <div className="footer-content">
              <div className="logo">
                <div className="normal">
                  <LogoCumbres />
                </div>
                <svg
                  className="mobile"
                  width="90px"
                  height="30px"
                  viewBox="0 0 92.09 29.14"
                  {...props}
                >
                  <path
                    d="M92.09.11H89L75.28 26.18 61.46 0H20.94l1.43 2.7h8.74l12.53 23.74h-10.9L18.79 0h-3.41L0 29.14h3.06L16.94 2.83l14.18 26.31h40.52l-1.42-2.7h-9.17L48.53 2.7h11.31l14 26.44h3zM58 26.44H46.69v-.06L34.16 2.7h11.31v.06z"
                    fill="#d3b29d"
                    data-name="Capa 2"
                  />
                </svg>
              </div>
              <div className="text">
                <p style={{ minWidth: "24em" }}>ENTÉRATE DE LOS DETALLES</p>
                <h1 className="title">TESTIMONIOS</h1>
              </div>
            </div>
            {state.options.length > 1 && (
              <>
                <div className="pagination">
                  <button
                    className="icon-button"
                    onClick={clickLeft}
                    disabled={state.selected === 0}
                  >
                    <LeftArrow />
                  </button>
                  <div className="pagination__items">
                    {state.options.map((_, index) => (
                      <>
                        <button
                          className={
                            "button icon-button " +
                            (state.selected === index ? "text-light" : "")
                          }
                          onClick={() => changeOptionGallery(index)}
                        >
                          {index + 1}
                        </button>
                        {index + 1 < state.options.length && (
                          <p
                            key={`${index}-separator-testimonial-button`}
                            className="line-space line line-small bg-green"
                          >
                            |
                          </p>
                        )}
                      </>
                    ))}
                  </div>
                  <button
                    className="icon-button"
                    onClick={clickRight}
                    disabled={state.selected + 1 >= state.options.length}
                  >
                    <RightArrow />
                  </button>
                </div>
                <ButtonLinkHeader
                  options={state.options}
                  optionSelected={state.selected}
                  changeOption={changeOptionGallery}
                />
              </>
            )}
          </div>
        </section>
      </Modal>
    </section>
  )
}

export default Testimonials
