import * as React from "react"

function LeftArrow(props) {
  return (
    <svg width={44.787} height={21.181} viewBox="0 0 44.787 21.181" {...props}>
      <g fill="none" stroke="#ccaa97" strokeWidth={2}>
        <path
          d="M696.124 3937.252l-9.884 9.883 9.884 9.883M686.156 3947.135h43.457"
          transform="translate(-684.826 -3936.545)"
          {...props}
        />
      </g>
    </svg>
  )
}

export default LeftArrow
