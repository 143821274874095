import React from "react"

interface Props {
  className?: string
}

const IconHand = (props: Props) => {
  return (
    <svg width={28.549} height={43.167} viewBox="0 0 28.549 43.167" {...props}>
      <g
        data-name="Group 382"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
      >
        <path
          data-name="Path 163"
          d="M64.774 94.986c-1.225-4.8-1.061-5.4-3.911-9.212a90.828 90.828 0 01-5.533-9.3 2.68 2.68 0 014.64-2.68l2.6 4.506c.612.933 2.227.165 2.19-.779V61.203a2.125 2.125 0 014.249 0v9.914a2.124 2.124 0 014.248 0v.982a2.125 2.125 0 114.249 0v1.993a2.125 2.125 0 014.249 0c.411 5.068.833 9.218-.981 12.844a58.9 58.9 0 00-2.343 8.051"
          transform="translate(-54.212 -52.736)"
        />
        <path
          data-name="Path 164"
          d="M71.038 63.886a4.991 4.991 0 10-8.306.1"
          transform="translate(-54.212 -52.736)"
        />
        <path
          data-name="Path 165"
          d="M72.142 66.738a7.68 7.68 0 10-9.408.918"
          transform="translate(-54.212 -52.736)"
        />
      </g>
    </svg>
  )
}

export default IconHand
