import { AnchorLink as Link } from "gatsby-plugin-anchor-links"
import React, { FormEvent } from "react"
import { useForm } from "react-hook-form"
import Button from "./Button"
import LogoCumbres from "./LogoCumbres"
interface Props {}

const Footer = (props: Props) => {
  const { formState, register, handleSubmit } = useForm({
    mode: "onChange",
  })

  const onSubmit = async (data, e: FormEvent) => {
    e.preventDefault()

    const contact = {
      name: data.name,
      email: data.email,
      subject: "Contacto RUMAH",
      message: `Ciudad: ${data.ciudad}, me interesa ${data.interes}, mi teléfono de contacto es ${data.telefono}, ${data.message}`,
      replyTo:
        "Muchas gracias por ponerse en contacto con nosotros, en breve nos comunicaremos para responder todas sus dudas.",
      accessKey: "c2b9f724-47ed-498f-9e05-66b89571a623",
    }

    try {
      const res = await fetch("https://api.staticforms.xyz/submit", {
        method: "POST",
        body: JSON.stringify(contact),
        headers: { "Content-Type": "application/json" },
      })
      const json = await res.json()
      if (json.success) {
        alert(
          "¡Gracias! Hemos recibido tu mensaje, pronto un asesor se pondrá en contacto contigo."
        )
      } else {
        alert(
          "Ha ocurrido un error al enviar el formulario, por favor intente mas tarde."
        )
      }
    } catch (e) {
      console.log("An error occurred", e)
    }
  }

  return (
    <footer id="contacto" className="footer-section">
      <div className="footer__left bg-green">
        <section className="form-section">
          <div className="form-content">
            <div className="main-title">
              <p>Escríbenos</p>
              <h2>AGENDA UNA CITA</h2>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <input type="hidden" name="_captcha" value="false"></input>
              <label htmlFor="nombre">
                Nombre
                <input name="name" type="text" ref={register} required />
              </label>
              <label htmlFor="email">
                Email
                <input name="email" type="email" ref={register} required />
              </label>
              <label htmlFor="telefono">
                Teléfono
                <input type="tel" name="telefono" ref={register} required />
              </label>
              <div className="input-row">
                <div className="multiple-options">
                  <label htmlFor="ciudad">ciudad</label>
                  <input type="text" name="ciudad" ref={register} required />
                </div>
                <div className="multiple-options">
                  <p>Te interesa:</p>
                  <div className="multiple-options-row">
                    <div className="container">
                      <input
                        type="radio"
                        name="interes"
                        id="habitar"
                        value="habitar"
                        ref={register}
                        className="styled-checkbox"
                      />
                      <label htmlFor="habitar">
                        <span className="wrap">habitar</span>
                      </label>
                    </div>
                    <div className="container">
                      <input
                        type="radio"
                        name="interes"
                        id="invertir"
                        value="invertir"
                        ref={register}
                        className="styled-checkbox"
                      />
                      <label htmlFor="invertir">
                        <span className="wrap">invertir</span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <label>
                Mensaje
                <textarea
                  name="message"
                  rows={3}
                  ref={register}
                  required
                ></textarea>
              </label>
              <div className="container">
                <input
                  type="checkbox"
                  id="terms"
                  name="terms"
                  required
                  className="styled-checkbox"
                />
                <label htmlFor="terms">
                  <span className="wrap">Términos y condiciones</span>
                </label>
              </div>

              <div className="form-submit">
                <a href="mailto:info@rumahcumbres.com">
                  <i>info@rumahcumbres.com</i>
                </a>
                <Button border>ENVIAR</Button>
              </div>

              {/* <Link className="privacy" to="/privacidad">
                Aviso de privacidad
              </Link> */}
            </form>
          </div>
        </section>
        <div id="map" className="map">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d233.25211822459613!2d-100.47220076113116!3d20.70884831196436!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d35710c77370a3%3A0x59a287b1237b8480!2sCalle%20Lago%20Zumpango%20249%2C%20Cumbres%20del%20Lago%2C%20Juriquilla%2C%20Qro.!5e0!3m2!1ses!2smx!4v1596241334512!5m2!1ses!2smx"
            allowFullScreen
            aria-hidden="false"
            tabIndex={0}
            className="map"
            frameBorder={0}
          />
        </div>
      </div>
      <div className="footer__right">
        <section className="footer-nav">
          <div className="content">
            <div className="logo-cumbres">
              <LogoCumbres />
            </div>
            <div className="footer-links">
              <div className="grouped-links">
                <Link to="#exclusividad" className="link">
                  Concepto
                </Link>
                <Link to="#amenidades" className="link">
                  Amenidades
                </Link>
                <Link to="#modalidades" className="link">
                  Modalidades
                </Link>
                <Link to="#acabados" className="link">
                  Acabados
                </Link>
                <Link to="#contacto" className="link">
                  Contacto
                </Link>
              </div>
              <div className="privacy-link">
                <Link to="/privacidad" className="link">
                  Aviso de privacidad
                </Link>
              </div>
            </div>
          </div>
        </section>
        <div className="footer__right--address text-green">
          <div className="footer__right--address-content">
            <div className="mini-logo">
              <svg
                viewBox="0 0 92.09 29.14"
                width="90px"
                height="30px"
                className="svg-green"
                {...props}
              >
                <path
                  d="M92.09.11H89L75.28 26.18 61.46 0H20.94l1.43 2.7h8.74l12.53 23.74h-10.9L18.79 0h-3.41L0 29.14h3.06L16.94 2.83l14.18 26.31h40.52l-1.42-2.7h-9.17L48.53 2.7h11.31l14 26.44h3zM58 26.44H46.69v-.06L34.16 2.7h11.31v.06z"
                  data-name="Capa 2"
                  fill="#004f51"
                />
              </svg>
            </div>
            <div className="address-content">
              <a className="line-botom" href="tel:+524424565177">
                (442) 456 5177
              </a>

              <a className="line-botom" href="mailto:info@rumahcumbres.com">
                info@rumahcumbres.com
              </a>
              <p>Calle Lago Zumpango 249-A, Cumbres del Lago</p>
              <p>Juriquilla, Querétaro. México</p>
              <p> </p>
            </div>
            <Link to="/privacidad" className="link">
              Aviso de privacidad
            </Link>

            <p className="footer__right--copyright text-green">
              RUMAH CUMBRES© 2020 <br></br>Todos los derechos reservados
            </p>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
