import { wrap } from "@popmotion/popcorn"
import { AnimatePresence, motion } from "framer-motion"
import React, { ReactChild, useEffect, useState } from "react"

interface Props {
  images?: any[]
  page?: number
  className?: string
  children?: ReactChild[]
}

const Slider = (props: Props) => {
  const [[page, direction], setPage] = useState([0, 0])
  const imageIndex = wrap(0, props.children.length, page)
  const paginate = (newDirection: number) => {
    setPage([page + newDirection, newDirection])
  }
  const variants = {
    enter: (direction: number) => {
      return {
        x: direction > 0 ? 1000 : -1000,
        opacity: 0,
      }
    },
    center: {
      zIndex: 1,
      x: 0,
      opacity: 1,
    },
    exit: (direction: number) => {
      return {
        zIndex: 0,
        x: direction < 0 ? 1000 : -1000,
        opacity: 0,
      }
    },
    in: {
      opacity: 1,
    },
    out: {
      opacity: 0,
    },
  }
  useEffect(() => {
    if (props.page) {
      setPage([props.page, 0])
    }
    return () => {}
  }, [props.page])
  return (
    <AnimatePresence initial={false} custom={direction}>
      <motion.div
        key={page}
        custom={direction}
        variants={variants}
        initial="enter"
        animate="in"
        exit="out"
        className={props.className}
        drag="x"
        transition={{
          x: { type: "spring", stiffness: 300, damping: 200 },
          opacity: { duration: 0.2 },
        }}
        dragConstraints={{ left: 0, right: 0 }}
        onDragEnd={(e, { offset, velocity }) => {
          const swipe = swipePower(offset.x, velocity.x)

          if (swipe < -swipeConfidenceThreshold) {
            paginate(1)
          } else if (swipe > swipeConfidenceThreshold) {
            paginate(-1)
          }
        }}
      >
        {props.children[imageIndex]}
      </motion.div>
    </AnimatePresence>
  )
}
const swipeConfidenceThreshold = 10000
const swipePower = (offset: number, velocity: number) => {
  return Math.abs(offset) * velocity
}
export default Slider
