import React, { useState } from "react"
import Button from "./Button"
import DownloadForm from "./DownloadForm"

interface Props {}

const BannerFooterDownload = (props: Props) => {
  const [clickDownload, setClickDownload] = useState(false)
  return (
    <section className="footer-download">
      <div className="footer-download--content">
        <p>DESCARGA MÁS INFORMACIÓN</p>
        <div className="footer-download__buttons">
          <Button
            className="button-modal"
            onClick={() => setClickDownload(true)}
          >
            BROCHURE DIGITAL
          </Button>
          {/* <Button>PLAN DE INVERSIÓN</Button> */}
        </div>
      </div>
      <DownloadForm
        showForm={clickDownload}
        closeForm={() => setClickDownload(false)}
      />
    </section>
  )
}

export default BannerFooterDownload
