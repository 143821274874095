import React, { useReducer } from "react"
import {
  ContenfulSeccion,
  formatDataStore,
  imagesConcept,
  reducer,
} from "../../store/reducers"
import ButtonLinkHeader from "../ButtonLinkHeader"
import LeftArrow from "../LeftArrow"
import RightArrow from "../RightArrow"
import ShowResponsiveMessage from "../ShowResponsiveMessage"
import SliderGallery from "../SliderGallery"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
interface Props {
  data: ContenfulSeccion
}

const Concepts = ({ data }: Props) => {
  const [state, dispatch] = useReducer(reducer, formatDataStore(data))
  const changeOptionGallery = index => {
    dispatch({ type: "CHANGE", payload: index })
  }
  const changeOptionGalleryImg = index => {
    dispatch({ type: "CHANGE_IMG", payload: index })
  }

  const clickRight = () => {
    const imgs = state.options[state.selected].img
    if (state.imgSelected === imgs.length - 1) return changeOptionGalleryImg(0)
    if (state.imgSelected + 1 < imgs.length)
      changeOptionGalleryImg(state.imgSelected + 1)
  }
  const clickLeft = () => {
    const imgs = state.options[state.selected].img
    if (state.imgSelected === 0) return changeOptionGalleryImg(imgs.length - 1)
    if (state.imgSelected - 1 >= 0)
      changeOptionGalleryImg(state.imgSelected - 1)
  }
  const SliderGalleryList = state.options.map(({ img, id }, index) => (
    <div
      className={`slider ${index === state.selected ? "show" : "hide"}`}
      key={id}
    >
      <SliderGallery
        onChange={changeOptionGalleryImg}
        page={state.imgSelected}
        images={img}
        className={[1, 2].includes(state.selected) ? "contain" : ""}
      />
    </div>
  ))
  return (
    <section id="conceptos" className="concepts">
      <div className="concepts--content">
        <h2 className="concepts--text__text subtitle text-cream">
          {data.subtitulo}
        </h2>
        <div className="links-button">
          <div className="concepts--header__links">
            <section className="buttons-links">
              {state.options.map((option, index) => {
                return (
                  <>
                    <button
                      className={`link-button ${
                        state.selected === index ? "active" : ""
                      }`}
                      key={index + "-button-link-options"}
                      onClick={() => changeOptionGallery(index)}
                    >
                      {option.title}
                    </button>
                    {index + 1 < state.options.length && (
                      <p
                        key={`${index}-separator-concepts`}
                        className="line-space "
                      >
                        |
                      </p>
                    )}
                  </>
                )
              })}
            </section>
          </div>
          <ButtonLinkHeader
            options={state.options}
            optionSelected={state.selected}
            changeOption={changeOptionGallery}
          />
        </div>
        <div className="concepts--text__header">
          <h2 className="concepts--text__title title">{data.titulo}</h2>
        </div>
        <div className="text__gallery">
          <div className="concepts--text-gallery_text">
            <div className="concepts--text__text">
              {documentToReactComponents(data.texto.json)}
            </div>
          </div>
        </div>
        <div className="gallery__imgs">
          <div className="concepts--gallery__imgs">{SliderGalleryList}</div>
        </div>
        <div className="footer__details">
          <div className="concepts--gallery__details">
            <p>VIVE CADA DETALLE DE TU ESPACIO</p>
          </div>
        </div>
        <div className="footer__options">
          <div className="concepts--gallery__footer_options">
            <div className="subtext">
              {state.options[state.selected].text.map((subtext, index) => (
                <>
                  <p key={"subtext-index-" + index}>{subtext}</p>
                  {index + 1 < state.options[state.selected].text.length}
                  {index < state.options[state.selected].text.length - 1 && (
                    <p
                      key={`${index}-separator-concepts`}
                      className="line-space "
                    >
                      |
                    </p>
                  )}
                </>
              ))}
            </div>
            {state.selected !== 0 && <ShowResponsiveMessage />}
            {state.options[state.selected].img.length > 1 && (
              <div className="pagination">
                <button
                  className="icon-button link-button arrow "
                  onClick={clickLeft}
                >
                  <LeftArrow />
                </button>
                <div className="pagination__items">
                  {state.options[state.selected].img.map((_, index) => (
                    <>
                      <button
                        disabled
                        className={
                          "button icon-button " +
                          (state.imgSelected === index ? "text-light" : "")
                        }
                        onClick={() => changeOptionGalleryImg(index + 1)}
                        key={`button-${index}-separator-concepts-button`}
                      >
                        {index + 1}
                      </button>
                      {index + 1 < state.options[state.selected].img.length && (
                        <p
                          key={`${index}-separator-concepts-button`}
                          className="line-space line line-small bg-green"
                        >
                          |
                        </p>
                      )}
                    </>
                  ))}
                </div>
                <button className="icon-button  arrow " onClick={clickRight}>
                  <RightArrow />
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  )
}

export default Concepts
