import React, { ReactElement } from "react"

interface Props {
  border?: Boolean
  classes?: String
  className?: String
  children?: ReactElement | String
  text?: String
  onClick?: VoidFunction
}

const Button = (props: Props) => {
  return (
    <button
      type="submit"
      className={
        "button " +
        ` ${props.classes} ${props.className} ` +
        (props.border ? "border" : "")
      }
      onClick={props.onClick}
    >
      {props.children || props.text}
    </button>
  )
}

export default Button
