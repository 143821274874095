import { graphql } from "gatsby"
import React from "react"
import BannerFooterDownload from "../components/BannerFooterDownload"
import Footer from "../components/Footer"
import Layout from "../components/layout"
import Amenities from "../components/rumah/Amenities"
import Concepts from "../components/rumah/Concepts"
import Finishes from "../components/rumah/Finishes"
import Home from "../components/rumah/Home"
import Modalities from "../components/rumah/Modalities"
import Testimonials from "../components/rumah/Testimonials"
import SEO from "../components/seo"
const IndexPage = ({ data }) => {
  const { edges } = data.allContentfulInicio
  const { allContentfulSecciones } = data
  const [
    conceptos,
    amenidades,
    modalidades,
    acabados,
    testimonios,
    detalles,
  ] = allContentfulSecciones?.edges
  // const { t } = useTranslation()
  // i18n.changeLanguage("es")

  return (
    // <Suspense fallback={<div>asdsads</div>}>
    <Layout>
      <SEO title="Rumah" />
      <Home data={edges[0]} />
      <BannerFooterDownload />
      <Concepts data={conceptos?.node} />
      <Testimonials data={[detalles?.node, testimonios?.node]} />
      <Amenities data={amenidades?.node} />
      <Modalities data={modalidades?.node} />
      <Finishes data={acabados?.node} />
      <Footer />
    </Layout>
    // </Suspense>
  )
}

export default IndexPage
export const query = graphql`
  {
    allContentfulInicio {
      edges {
        node {
          imagenes {
            file {
              url
            }
          }
          id
          titulo
          texto
          videoUrl
        }
      }
    }
    allContentfulSecciones(sort: { fields: createdAt }) {
      edges {
        node {
          titulo
          subtitulo
          id
          galeriaDeImagenes {
            imagenes {
              file {
                url
              }
            }
            listaDeDetalles
            titulo
            url
            texto {
              json
            }
          }
          texto {
            json
          }
        }
      }
    }
  }
`
