import { Link } from "gatsby"
import React, { FormEvent, useState } from "react"
import { useForm } from "react-hook-form"
import Modal from "react-modal"
import PDFfile from "../files/brochure.pdf"
import Button from "./Button"
import IconCross from "./IconCross"
interface Props {
  showForm: boolean
  closeForm: VoidFunction
}

const customStyles = {
  content: {
    top: "0%",
    left: "50%",
    right: "0",
    bottom: "0",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    zIndex: 1999,
  },
}
const DownloadForm = (props: Props) => {
  const { formState, register, handleSubmit } = useForm({
    mode: "onChange",
  })
  const [response, setResponse] = useState({
    type: "",
    message: "",
  })
  const onSubmit = async (data, e: FormEvent) => {
    e.preventDefault()
    const contact = {
      name: data.name,
      email: data.email,
      subject: "Contacto RUMAH",
      message: `Hola, soy ${data.name}, de ${data.ciudad}, estoy interesado en ${data.interes}, mi teléfono de contacto es: ${data.telefono}`,
      replyTo:
        "Muchas gracias por ponerse en contacto con nosotros, en breve nos comunicaremos para responder todas sus dudas.",
      accessKey: "c2b9f724-47ed-498f-9e05-66b89571a623",
    }

    try {
      const res = await fetch("https://api.staticforms.xyz/submit", {
        method: "POST",
        body: JSON.stringify(contact),
        headers: { "Content-Type": "application/json" },
      })

      const json = await res.json()

      if (json.success) {
        setResponse({
          type: "success",
          message: "Thank you for reaching out to us.",
        })
      } else {
        setResponse({
          type: "error",
          message: json.message,
        })
      }
    } catch (e) {
      console.log("An error occurred", e)
      setResponse({
        type: "error",
        message: "An error occured while submitting the form",
      })
    }

    onSubmitbutton()
  }

  const onSubmitbutton = () => {
    const url = new URL(`${location.origin}${PDFfile}`)
    const a = document.createElement("a")
    a.target = "_blank"
    a.href = url.toString()
    a.download = "brochure"
    a.click()
  }

  return (
    <Modal
      style={{
        overlay: {
          zIndex: 9999,
        },
        content: {
          position: "absolute",
          top: "0px",
          left: "0px",
          right: "0px",
          bottom: "0px",
          border: "none",
          background: "var(--accent-green)",
          overflow: "auto",
          WebkitOverflowScrolling: "touch",
          borderRadius: "0px",
          outline: "none",
          padding: "20px",
          display: "flex",
          justifyContent: "center",
        },
      }}
      closeModal={() => props.closeForm()}
      isOpen={props.showForm}
    >
      <div className="downloadForm">
        <div className="header-form-modal">
          <p className="text-cream">
            ¡Ya casi estamos listos para la descarga!
          </p>
          <Button className="icon-button" onClick={() => props.closeForm()}>
            <IconCross />
          </Button>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="fields">
            <div>
              <label htmlFor="nombre">Nombre</label>
              <input
                name="name"
                type="text"
                ref={register({ required: true })}
              />
            </div>
            <div>
              <label htmlFor="email">Email</label>
              <input
                name="email"
                type="email"
                ref={register({ required: true })}
              />
            </div>

            <div>
              <label htmlFor="telefono">Teléfono</label>
              <input
                type="tel"
                name="telefono"
                ref={register({ required: true })}
              />
            </div>
            <div className="input-row">
              <div>
                <label htmlFor="telefono">ciudad</label>
                <input
                  type="text"
                  name="ciudad"
                  ref={register({ required: true })}
                />
              </div>
              <div className="multiple-options">
                <span>Te interesa:</span>
                <div className="multiple-options-row">
                  <div className="container">
                    <input
                      type="radio"
                      name="interes"
                      id="habitar2"
                      value="habitar"
                      ref={register}
                      className="styled-checkbox"
                    />
                    <label htmlFor="habitar2">
                      <span className="wrap">habitar</span>
                    </label>
                  </div>
                  <div className="container">
                    <input
                      type="radio"
                      name="interes"
                      id="invertir2"
                      value="invertir"
                      ref={register}
                      className="styled-checkbox"
                    />
                    <label htmlFor="invertir2">
                      <span className="wrap">invertir</span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="container">
              <input
                type="checkbox"
                id="terms2"
                name="terms2"
                required
                className="styled-checkbox"
              />
              <label htmlFor="terms2">
                <span className="wrap">Términos y condiciones</span>
              </label>
            </div>
            <div className="form-submit">
              <Button border>Descargar</Button>
              <Link className="privacy" to="/privacidad" target="_blank">
                Aviso de privacidad
              </Link>
            </div>
          </div>
        </form>
      </div>
    </Modal>
  )
}

export default DownloadForm
