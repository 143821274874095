import React, { useReducer } from "react"
import {
  ContenfulSeccion,
  formatDataStore,
  reducer,
} from "../../store/reducers"
import ButtonLinkHeader from "../ButtonLinkHeader"
import GridComponent from "../GridComponent"
import LeftArrow from "../LeftArrow"
import RightArrow from "../RightArrow"
import ShowResponsiveMessage from "../ShowResponsiveMessage"
import SliderGallery from "../SliderGallery"
interface Props {
  data: ContenfulSeccion
}

const Finishes = ({ data }: Props) => {
  const [state, dispatch] = useReducer(reducer, formatDataStore(data))
  const changeOptionGallery = index => {
    dispatch({ type: "CHANGE", payload: index })
  }
  const changeOptionGalleryImg = index => {
    dispatch({ type: "CHANGE_IMG", payload: index })
  }
  const clickRight = () => {
    const imgs = state.options[state.selected].img
    if (state.imgSelected === imgs.length - 1) return changeOptionGalleryImg(0)
    if (state.imgSelected + 1 < imgs.length)
      changeOptionGalleryImg(state.imgSelected + 1)
  }
  const clickLeft = () => {
    const imgs = state.options[state.selected].img
    if (state.imgSelected === 0) return changeOptionGalleryImg(imgs.length - 1)
    if (state.imgSelected - 1 >= 0)
      changeOptionGalleryImg(state.imgSelected - 1)
  }

  const SliderGalleryList = state.options.map(({ img, id }, index) => (
    <div
      className={`slider ${index === state.selected ? "show" : "hide"}`}
      key={id}
    >
      <SliderGallery
        onChange={changeOptionGalleryImg}
        page={state.imgSelected}
        images={img}
      />
    </div>
  ))
  return (
    <section
      id="acabados"
      className="section-layout bg-ligth text-green finishes-section"
    >
      <div className="section-layout__content">
        <GridComponent>
          <section className="grid-component__subtitle">
            <h4 className="subtitle text-green">CALIDAD EN</h4>
          </section>
          <div className="grid-component__title">
            <h2 className="title">ACABADOS</h2>
          </div>
          <section className="grid-component__options">
            <div className="links-button">
              {state.options.map((option, index) => {
                return (
                  <>
                    <button
                      className={`link-button ${
                        state.selected === index ? "active" : ""
                      }`}
                      key={index}
                      onClick={() => changeOptionGallery(index)}
                    >
                      {option.title}
                    </button>
                    {index + 1 < state.options.length && (
                      <p
                        key={`${index}-separator-finishes`}
                        className="line-space text-green"
                      >
                        |
                      </p>
                    )}
                  </>
                )
              })}
            </div>
            <ButtonLinkHeader
              options={state.options}
              optionSelected={state.selected}
              changeOption={changeOptionGallery}
            />
          </section>
          <div className="grid-component__text text-cream">
            <div className="description">
              <p className="text-green">
                En <i>Rumah Cumbres®</i> cuidamos el detalle de nuestros
                acabados con materiales de alta gama. Somos el único residencial
                de lujo en Querétaro que ofrece acabados premium desde las
                amenidades hasta el interior de tu departamento.
              </p>
              <p className="text-green">
                Revisa los detalles de acabados que tenemos disponibles y las
                diferentes opciones con las que puedes personalizar algunas
                áreas de tu nuevo departamento.
              </p>
            </div>
            <div className="details text-green">
              <h2 className="subtitle text-green">
                {state.options[state.selected].title}
              </h2>
              <div className="">
                {state.options[state.selected].text.map((text, index) => (
                  <p
                    className="line-botom border-green text-green"
                    key={"p-" + index}
                  >
                    {text}
                  </p>
                ))}
              </div>
            </div>
            <p className="appointment">
              AGENDA UNA CITA Y CONOCE CADA MATERIAL A DETALLE
            </p>
          </div>
          <div className="details">
            <p className="text-green">
              ENCUENTRA {state.options[state.selected].title} EN:
            </p>
            {state.options[state.selected].text.map((text, index) => (
              <p
                className="line-botom border-green text-green"
                key={"p-t-" + index}
              >
                {text}
              </p>
            ))}
            <span className="text-green">
              {state.options[state.selected].message}
            </span>
          </div>
          <div className="grid-component__imgs">
            <div className="container__imgs">{SliderGalleryList}</div>
            {state.options[state.selected].img.length > 1 && (
              <div className="pagination">
                <button
                  className="icon-button link-button arrow"
                  onClick={clickLeft}
                >
                  <LeftArrow className="link-button icon-button" />
                </button>
                <div className="pagination__items">
                  {state.options[state.selected].img.map((_, index) => (
                    <>
                      <button
                        disabled
                        className={
                          "button icon-button " +
                          (state.imgSelected === index
                            ? "text-light"
                            : "text-cream")
                        }
                        onClick={() => changeOptionGalleryImg(index + 1)}
                        key={"btn-" + index + 1}
                      >
                        {index + 1}
                      </button>
                      {index + 1 < state.options[state.selected].img.length && (
                        <p
                          key={"p-btn-" + index}
                          className="line-space text-green"
                        >
                          |
                        </p>
                      )}
                    </>
                  ))}
                </div>
                <button className="icon-button arrow " onClick={clickRight}>
                  <RightArrow className="link-button icon-button" />
                </button>
              </div>
            )}
          </div>
          <div className="grid-component__select">
            {state.options[state.selected].img.length > 1 && (
              <div className="pagination">
                <button
                  className="icon-button link-button arrow"
                  onClick={clickLeft}
                >
                  <LeftArrow className="link-button icon-button" />
                </button>
                <div className="pagination__items">
                  {state.options[state.selected].img.map((_, index) => (
                    <>
                      <button
                        disabled
                        className={
                          "button icon-button " +
                          (state.imgSelected === index
                            ? "text-light"
                            : "text-cream")
                        }
                        onClick={() => changeOptionGalleryImg(index + 1)}
                        key={"btn-" + index + 1}
                      >
                        {index + 1}
                      </button>
                      {index + 1 < state.options[state.selected].img.length && (
                        <p
                          key={"p-btn-" + index}
                          className="line-space text-green"
                        >
                          |
                        </p>
                      )}
                    </>
                  ))}
                </div>
                <button className="icon-button arrow " onClick={clickRight}>
                  <RightArrow className="link-button icon-button" />
                </button>
              </div>
            )}
            <ShowResponsiveMessage className="text-cream" drag />
          </div>
        </GridComponent>
      </div>
    </section>
  )
}

export default Finishes
