import React from "react"
import IconHand from "./IconHand"

interface Props {
  className?: string
  drag?: Number
}

const ShowResponsiveMessage = (props: Props) => {
  const renderOption = option => {
    switch (option) {
      case 1:
        return "DESLIZA  EL DEDO SOBRE LA PANTALLA PARA VER MÁS INFO"
      case 2:
        return "PULSA LA IMAGEN PARA ACCEDER A UN TOUR VIRTUAL"
      default:
        return "DESLIZA EL DEDO SOBRE LA IMAGEN PARA NAVEGAR EN LA GALERÍA"
    }
  }
  return (
    <div className="responsive-message">
      <IconHand className={"icon " + (props.className || "")} />
      <p className={"message " + (props.className ? props.className : "")}>
        {renderOption(props.drag)}
      </p>
    </div>
  )
}

export default ShowResponsiveMessage
