import { nanoid } from "nanoid"
import VistaArq1 from "../images/arquitectonicas-01.jpg"
import VistaArq2 from "../images/arquitectonicas-02.jpg"
import Fachada from "../images/fachada-principal.jpg"
import ABA from "../images/img_alto brillo/alto-brillo-01.jpg"
import ABB from "../images/img_alto brillo/alto-brillo-02.jpg"
import ABC from "../images/img_alto brillo/alto-brillo-03.jpg"
import ALB from "../images/img_amenidades/alberca-01.jpg"
import GYM from "../images/img_amenidades/gimnasio-01.jpg"
import JC from "../images/img_amenidades/jacuzzi-01.jpg"
import OAS from "../images/img_amenidades/oasis-01.jpg"
import PUGA from "../images/img_amenidades/putting-01.jpg"
import SU1 from "../images/img_amenidades/salon-01.jpg"
import SU2 from "../images/img_amenidades/salon-02.jpg"
import AZA from "../images/img_amenidades/zen-01.jpg"
import CPA from "../images/img_canceleria/canceleria-01.jpg"
import CPB from "../images/img_canceleria/canceleria-02.jpg"
import CNA from "../images/img_chapa/chapa-nogal-01.jpg"
import CNB from "../images/img_chapa/chapa-nogal-02.jpg"
import CZA from "../images/img_cuarzo/cuarzo-01.jpg"
import CZB from "../images/img_cuarzo/cuarzo-02.jpg"
import PGA from "../images/img_galarza/galarza-01.jpg"
import PGB from "../images/img_galarza/galarza-02.jpg"
import GRA from "../images/img_granito/granito-01.jpg"
import GRB from "../images/img_granito/granito-02.jpg"
import LEA from "../images/img_loseta/loseta-01.jpg"
import LEB from "../images/img_loseta/loseta-02.jpg"
import M01A from "../images/img_m01/m01-01.jpg"
import M01B from "../images/img_m01/m01-02.jpg"
import M02A from "../images/img_m02/m02-01.jpg"
import M02B from "../images/img_m02/m02-02.jpg"
import M03A from "../images/img_m03/m03-01.jpg"
import M03B from "../images/img_m03/m03-02.jpg"
import M04A from "../images/img_m04/m04-01.jpg"
import M04B from "../images/img_m04/m04-02.jpg"
import POA from "../images/img_porcelanato/porcelanato-01.jpg"
import POB from "../images/img_porcelanato/porcelanato-02.jpg"
import Torre1A from "../images/torre1-01.png"
import Torre1P from "../images/torre1-02.png"
import Torre2E from "../images/torre2-01.png"
import Torre2U from "../images/torre2-02.png"
export interface Info {
  selected: Number
  options: Option[]
  imgSelected?: Number
}

export interface Option {
  title: String | string[]
  img: { img: ImageBitmap; id: string }[]
  text: String[]
  subtext: String[]
  message?: String[]
  link?: String
  id: string
}

export interface ContenfulSeccion {
  galeriaDeImagenes: SeccionImagenes[]
  titulo: string
  texto: any
  subtitulo: string
}

export interface SeccionImagenes {
  titulo: string
  imagenes: { file: { url: string } }[]
  listaDeDetalles: string[]
  url?: string
  texto?: any
}

export const reducer = (state, action) => {
  if (action.type === "CHANGE") {
    return {
      ...state,
      selected: action.payload,
      imgSelected: action.imgSelected || 0,
    }
  }
  if (action.type === "CHANGE_IMG") {
    return {
      ...state,
      imgSelected: action.payload,
    }
  }
  return state[action.index]
}

export const formatDataStore = (data: ContenfulSeccion) => {
  const options = data.galeriaDeImagenes.map(item => ({
    title: item.titulo,
    text: item.listaDeDetalles || item.texto,
    img: item.imagenes.map(({ file: { url } }) => ({ img: url })),
    link: item.url,
  }))
  return {
    selected: 0,
    imgSelected: 0,
    options: options,
  }
}

export const imagesConcept: Info = {
  selected: 0,
  imgSelected: 0,
  options: [
    {
      id: nanoid(),
      title: "fachada principal",
      img: [{ id: nanoid(), img: Fachada }],
      text: [
        `Contamos con 42 departamentos de lujo distribuidos en dos torres, con 2 modalidades de departamentos y 2 de penthouse, adaptados para diferentes estilos de vivir.`,
        `Además, ofrecemos un entorno acompañado de amenidades exclusivas que permitirán que tu hogar sea tu lugar favorito. Somos la garantía de una vida exclusiva, tranquila y segura.`,
      ],
      subtext: [
        "2 TORRES",
        "CAPTACIÓN DE AGUAS PLUVIALES",
        "CELDAS FOTOVOLTAICAS",
        "AGUAS JABONOSAS",
      ],
    },
    {
      id: nanoid(),
      title: "torre 1",
      img: [
        { id: nanoid(), img: Torre1A },
        { id: nanoid(), img: Torre1P },
      ],
      text: [
        `Contamos con 42 departamentos de lujo distribuidos en dos torres, con 2 modalidades de departamentos y 2 de penthouse, adaptados para diferentes estilos de vivir.`,
        `Además, ofrecemos un entorno acompañado de amenidades exclusivas que permitirán que tu hogar sea tu lugar favorito. Somos la garantía de una vida exclusiva, tranquila y segura.`,
      ],
      subtext: ["18 DEPARTAMENTOS", "4 PENTHOUSE", "1 ELEVADOR"],
    },
    {
      id: nanoid(),
      title: "torre 2",
      img: [
        { id: nanoid(), img: Torre2E },
        { id: nanoid(), img: Torre2U },
      ],
      text: [
        `Contamos con 42 departamentos de lujo distribuidos en dos torres, con 2 modalidades de departamentos y 2 de penthouse, adaptados para diferentes estilos de vivir.`,
        `Además, ofrecemos un entorno acompañado de amenidades exclusivas que permitirán que tu hogar sea tu lugar favorito. Somos la garantía de una vida exclusiva, tranquila y segura.`,
      ],
      subtext: ["16 DEPARTAMENTOS", "4 PENTHOUSE", "1 ELEVADOR"],
    },
    {
      id: nanoid(),
      title: "vista arquitectónica",
      img: [
        { id: nanoid(), img: VistaArq1 },
        { id: nanoid(), img: VistaArq2 },
      ],
      text: [
        `Contamos con 42 departamentos de lujo distribuidos en dos torres, con 2 modalidades de departamentos y 2 de penthouse, adaptados para diferentes estilos de vivir.`,
        `Además, ofrecemos un entorno acompañado de amenidades exclusivas que permitirán que tu hogar sea tu lugar favorito. Somos la garantía de una vida exclusiva, tranquila y segura.`,
      ],
      subtext: ["AMENIDADES DE LUJO", "UBICACIÓN PRIVILEGIADA"],
    },
  ],
}

export const imagesAmenidades: Info = {
  selected: 0,
  imgSelected: 0,
  options: [
    {
      id: nanoid(),
      title: "alberca",
      img: [{ id: nanoid(), img: ALB }],
      text: [
        "56 m²  |  1.50 m de profundidad",
        "Calefacción con paneles solares",
        "Área de pérgola para descanso",
      ],
      subtext: [],
    },
    {
      id: nanoid(),
      title: "gimnasio",
      img: [{ id: nanoid(), img: GYM }],
      subtext: [],
      text: [
        "Equipo de sonido surround",
        "Equipo profesional de gimnasio nuevo",
        "Entrenamiento aeróbico y anaeróbico ",
      ],
    },
    {
      id: nanoid(),
      title: "jacuzzi",
      img: [{ id: nanoid(), img: JC }],
      subtext: [],
      text: [
        "Jacuzzi para 9 personas",
        "Calefacción con paneles solares",
        "Vestidores con regadera y lockers",
      ],
    },
    {
      id: nanoid(),
      title: "OASIS",
      img: [{ id: nanoid(), img: OAS }],
      subtext: [],
      text: [
        `2 oasis equipados`,
        `Oasis 1: Asador, bar y sala de tv`,
        `Oasis 2: Billar, póker y sala de tv `,
      ],
    },
    {
      id: nanoid(),
      title: "salón",
      img: [
        { id: nanoid(), img: SU1 },
        { id: nanoid(), img: SU2 },
      ],
      text: [
        "Cocina integral | Capacidad: 50 personas",
        "Equipo de sonido surround",
        "2 Baños",
      ],
      subtext: [],
    },
    {
      id: nanoid(),
      title: "putting green",
      img: [{ id: nanoid(), img: PUGA }],
      subtext: [],
      text: [
        "40m²  |  5 hoyos",
        "Capacidad máxima : 4 jugadores",
        "Frente a oasis 1",
      ],
    },
    {
      id: nanoid(),
      title: "área zen",
      img: [{ id: nanoid(), img: AZA }],
      text: [
        "Espacio con fuente central",
        "2 bancas gualdras para descanso",
        "Área con techumbre  |  Protección solar",
      ],
      subtext: [],
    },
  ],
}

export const imagesModalities: Info = {
  selected: 0,
  imgSelected: 0,
  options: [
    {
      id: nanoid(),
      img: [
        { id: nanoid(), img: M01A },
        { id: nanoid(), img: M01B },
      ],
      subtext: ["144m²"],
      text: [
        "<p>  2 recámaras</p>",
        "<p>  2 baños completos</p>",
        "<p>2 estacionamientos</p>",
        "<p>(1 techado / 1 exterior)</p>",
        "<p>  Bodega en sótano</p>",
        "<p>  Terraza</p>",
      ],
      title: ["M01 ESTÁNDAR", "M01"],
      link: "https://my.matterport.com/show/?m=VQjj3dcjmmS&back=1",
    },
    {
      id: nanoid(),
      link: `/plus`,
      img: [
        { id: nanoid(), img: M02A },
        { id: nanoid(), img: M02B },
      ],
      subtext: ["160m²"],
      text: [
        "<p>2 baños completos</p>",
        "<p>2 recámaras</p>",
        "<p>2 estacionamientos &#10;(1 techado / 1 exterior)</p>",
        "<p>Bodega en sótano</p>",
        "<p>1 tercer espacio</p>",
        "<p>Bodega en vestíbulo</p>",
        "<p>Terraza</p>",
      ],
      title: ["M02 PLUS", "M02"],
    },
    {
      id: nanoid(),
      img: [
        { id: nanoid(), img: M03A },
        { id: nanoid(), img: M03B },
      ],
      subtext: ["287m²"],
      text: [
        "<p>3 recámaras</p>",
        "<p>3 1/2 baños completos</p>",
        "<p>3 estacionamientos <br/> (2 techados / 1 exterior)</p>",
        "<p>Bodega en sótano</p>",
        "<p>Cuarto de servicio</p>",
        "<p>Roof Garden</p>",
        "<p>Terraza</p>",
      ],
      title: ["M03 PENTHOUSE ESTÁNDAR", "M03"],
      link: `/penthouse`,
    },
    {
      id: nanoid(),
      img: [
        { id: nanoid(), img: M04A },
        { id: nanoid(), img: M04B },
      ],
      subtext: ["320m²"],
      text: [
        "<p>3 recámaras</p>",
        "<p>3 1/2 baños completos</p>",
        "<p>3 estacionamientos <br/> (2 techados / 1 exterior)</p>",
        "<p>Bodega en sótano y vestíbulo</p>",
        "<p>Cuarto de servicio</p>",
        "<p>Roof Garden y Family room</p>",
        "<p>Terraza</p>",
      ],
      title: ["M04 PENTHOUSE PLUS", "M04"],
      link: `/penthouse-plus`,
    },
  ],
}

export const imagesFinishes: Info = {
  selected: 0,
  imgSelected: 0,
  options: [
    {
      id: nanoid(),
      title: "LOSETA",
      img: [
        {
          id: nanoid(),
          img: LEA,
        },
        {
          id: nanoid(),
          img: LEB,
        },
      ],
      subtext: [],
      text: ["Muros de baño", "Área de amenidades"],
      message: ["Loseta con textura de piedra para muros de acento"],
    },
    {
      id: nanoid(),
      img: [
        {
          id: nanoid(),
          img: CZA,
        },
        {
          id: nanoid(),
          img: CZB,
        },
      ],
      text: [
        "Barras / contrabarras",
        "Cubiertas de baño",
        "Repisas en cuartos de lavado",
        "Detalles interiores en clósets",
      ],
      title: "CUARZO",
      subtext: [],
      message: [],
    },
    {
      id: nanoid(),
      img: [
        {
          id: nanoid(),
          img: ABA,
        },
        {
          id: nanoid(),
          img: ABB,
        },
        {
          id: nanoid(),
          img: ABC,
        },
      ],
      text: ["Cajones y puertas en cocina", "Acabados en clósets"],
      title: "ALTO BRILLO",
      subtext: [],
      message: [
        "Personaliza las puertas de tus clósets con alto brillo gris o hueso",
      ],
    },
    {
      id: nanoid(),
      img: [
        {
          id: nanoid(),
          img: CPA,
        },
        {
          id: nanoid(),
          img: CPB,
        },
      ],
      text: [
        "Ventanas en departamentos",
        "Puertas y ventanas en amenidades",
        "Bodegas",
      ],
      subtext: [],
      title: "CANCELERÍA PVC",
      message: ["Tecnología alemana térmica y aislante de sonido"],
    },
    {
      id: nanoid(),
      img: [
        {
          id: nanoid(),
          img: GRA,
        },
        {
          id: nanoid(),
          img: GRB,
        },
      ],
      text: ["Barra de  cocina", "Mobiliario en amenidades"],
      title: "GRANITO",
      subtext: [],
      message: [
        "¡Personaliza la barra de tu cocina! Consulta con tu asesor las opciones de granitos disponibles",
      ],
    },
    {
      id: nanoid(),
      img: [
        {
          id: nanoid(),
          img: POA,
        },
        {
          id: nanoid(),
          img: POB,
        },
      ],
      text: [
        "Pisos de porcelanato rectificado",
        "Detalle con relieve en cocina",
      ],
      title: "PORCELANATO",
      subtext: [],
      message: ["Elige laminado o porcelanato para los pisos de tus recámaras"],
    },
    {
      id: nanoid(),
      img: [
        {
          id: nanoid(),
          img: CNA,
        },
        {
          id: nanoid(),
          img: CNB,
        },
      ],
      text: [
        "Puertas de acceso e interiores",
        "Zoclos y vigas",
        "En amenidades y departamentos",
      ],
      title: "CHAPA DE NOGAL",
      subtext: [],
      message: [],
    },
    {
      id: nanoid(),
      img: [
        {
          id: nanoid(),
          img: PGA,
        },
        {
          id: nanoid(),
          img: PGB,
        },
      ],
      text: [
        "Muros exteriores y estacionamiento",
        "Muros en amenidades",
        "Sala de tu departamento",
      ],
      title: "GALARZA",
      subtext: [],
      message: [],
    },
  ],
}
