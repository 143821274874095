import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import React, { useReducer } from "react"
import {
  ContenfulSeccion,
  formatDataStore,
  reducer,
} from "../../store/reducers"
import ButtonLinkHeader from "../ButtonLinkHeader"
import GridComponent from "../GridComponent"
import IconBt from "../IconBt"
import LeftArrow from "../LeftArrow"
import RightArrow from "../RightArrow"
import ShowResponsiveMessage from "../ShowResponsiveMessage"
import SliderGallery from "../SliderGallery"

interface Props {
  data: ContenfulSeccion
}

const Amenities = ({ data }: Props) => {
  const [state, dispatch] = useReducer(reducer, formatDataStore(data))
  const changeOptionGallery = index => {
    dispatch({ type: "CHANGE", payload: index })
  }
  const changeOptionGalleryImg = index => {
    dispatch({ type: "CHANGE_IMG", payload: index })
  }

  const clickRight = () => {
    const imgs = state.options[state.selected].img
    if (state.imgSelected === imgs.length - 1) return changeOptionGalleryImg(0)
    if (state.imgSelected + 1 < imgs.length)
      changeOptionGalleryImg(state.imgSelected + 1)
  }
  const clickLeft = () => {
    const imgs = state.options[state.selected].img
    if (state.imgSelected === 0) return changeOptionGalleryImg(imgs.length - 1)
    if (state.imgSelected - 1 >= 0)
      changeOptionGalleryImg(state.imgSelected - 1)
  }
  const SliderGalleryList = state.options.map(({ img, id }, index) => (
    <div
      className={`slider ${index === state.selected ? "show" : "hide"}`}
      key={id}
    >
      <SliderGallery
        onChange={changeOptionGalleryImg}
        page={state.imgSelected}
        images={img}
      />
    </div>
  ))
  return (
    <section
      id="amenidades"
      className="section-layout bg-green amenities-section"
    >
      <div className="section-layout__content">
        <GridComponent>
          <section className="grid-component__subtitle">
            <h4 className="subtitle">{data?.subtitulo}</h4>
          </section>
          <section className="grid-component__options">
            <div className="links-button">
              {state.options.map((option, index) => {
                return (
                  <>
                    <button
                      className={`link-button ${
                        state.selected === index ? "active" : ""
                      }`}
                      key={index + "-img-button"}
                      onClick={() => changeOptionGallery(index)}
                    >
                      {option.title}
                    </button>
                    {index + 1 < state.options.length && (
                      <p
                        key={`${index}-separator-amenities`}
                        className="line-space "
                      >
                        |
                      </p>
                    )}
                  </>
                )
              })}
            </div>
            <ButtonLinkHeader
              options={state.options}
              optionSelected={state.selected}
              changeOption={changeOptionGallery}
            />
          </section>
          <div className="grid-component__title">
            <h2 className="title">{data?.titulo}</h2>
          </div>
          <div className="grid-component__text text-cream">
            <div>{documentToReactComponents(data.texto.json)}</div>
            <div className="details">
              <h2 className="subtitle">
                {state.options[state.selected].title}
              </h2>
              <div>
                {state.options[state.selected].text.map(
                  (text: string, index) => (
                    <p className="line-botom" key={index + "-text-amenity"}>
                      {text}{" "}
                      {text.includes("surround") ? (
                        <IconBt width={16} heigth={16} />
                      ) : null}
                    </p>
                  )
                )}
              </div>
            </div>
          </div>
          <div className="details">
            {state.options[state.selected].text.map((text: string, index) => (
              <p className="line-botom" key={index + "-text-amenity"}>
                {text} {text.includes("surround") ? <IconBt /> : null}
              </p>
            ))}{" "}
          </div>
          <div className="grid-component__imgs">
            <div className="container__imgs">{SliderGalleryList}</div>
            {state.options[state.selected].img.length > 1 && (
              <div className="pagination">
                <button
                  className="icon-button link-button arrow "
                  onClick={clickLeft}
                >
                  <LeftArrow className="link-button  icon-button" />
                </button>
                <div className="pagination__items">
                  {state.options[state.selected].img.map((_, index) => (
                    <>
                      <button
                        key={index + "-select-img-amenity"}
                        className={
                          "button icon-button " +
                          (state.imgSelected === index ? "text-light" : "")
                        }
                        onClick={clickLeft}
                        disabled
                      >
                        {index + 1}
                      </button>
                      {index + 1 < state.options[state.selected].img.length && (
                        <p
                          key={`${index}-separator-amenities-button`}
                          className="line-space line line-small bg-green"
                        >
                          |
                        </p>
                      )}
                    </>
                  ))}
                </div>
                <button className="icon-button  arrow " onClick={clickRight}>
                  <RightArrow className="link-button icon-button" />
                </button>
              </div>
            )}
          </div>
          <div className="grid-component__select">
            {state.options[state.selected].img.length > 1 && (
              <div className="pagination">
                <button
                  className="icon-button link-button arrow "
                  onClick={clickLeft}
                >
                  <LeftArrow className="link-button  icon-button" />
                </button>
                <div className="pagination__items">
                  {state.options[state.selected].img.map((_, index) => (
                    <>
                      <button
                        key={index + "-select-img-amenity"}
                        className={
                          "button icon-button " +
                          (state.imgSelected === index ? "text-light" : "")
                        }
                        onClick={clickLeft}
                        disabled
                      >
                        {index + 1}
                      </button>
                      {index + 1 < state.options[state.selected].img.length && (
                        <p
                          key={`${index}-separator-amenities-button`}
                          className="line-space line line-small bg-green"
                        >
                          |
                        </p>
                      )}
                    </>
                  ))}
                </div>
                <button className="icon-button  arrow " onClick={clickRight}>
                  <RightArrow className="link-button icon-button" />
                </button>
              </div>
            )}
            <ShowResponsiveMessage />
          </div>
        </GridComponent>
      </div>
    </section>
  )
}

export default Amenities
