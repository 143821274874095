import React, { ReactChild } from "react"

interface Props {
  position?: "left" | "right"
  children?: ReactChild[] | ReactChild
}

const GridComponent = (props: Props) => {
  return (
    <div
      className={"grid-component" + (props.position === "left" ? "--left" : "")}
    >
      {props.children}
    </div>
  )
}

export default GridComponent
