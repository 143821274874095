import React, { useReducer } from "react"
import {
  ContenfulSeccion,
  formatDataStore,
  reducer,
} from "../../store/reducers"
import Button from "../Button"
import ButtonLinkHeader from "../ButtonLinkHeader"
import GridComponent from "../GridComponent"
import LeftArrow from "../LeftArrow"
import RightArrow from "../RightArrow"
import ShowResponsiveMessage from "../ShowResponsiveMessage"
import SliderGallery from "../SliderGallery"
interface Props {
  data: ContenfulSeccion
}

const Modalities = ({ data }: Props) => {

  const [state, dispatch] = useReducer(reducer, formatDataStore(data))
  const changeOptionGallery = index => {
    dispatch({ type: "CHANGE", payload: index, imgSelected: 0 })
  }
  const changeOptionGalleryImg = index => {
    dispatch({ type: "CHANGE_IMG", payload: index })
  }
  const clickRight = () => {
    const imgs = state.options[state.selected].img
    if (state.imgSelected === imgs.length - 1) return changeOptionGalleryImg(0)
    if (state.imgSelected + 1 < imgs.length)
      changeOptionGalleryImg(state.imgSelected + 1)
  }
  const clickLeft = () => {
    const imgs = state.options[state.selected].img
    if (state.imgSelected === 0) return changeOptionGalleryImg(imgs.length - 1)
    if (state.imgSelected - 1 >= 0)
      changeOptionGalleryImg(state.imgSelected - 1)
  }

  const [first, ...rest] = state.options[state.selected].title[0].split(" ")

  const SliderGalleryList = state.options.map(({ img, id }, index) => (
    <div
      className={`slider ${index === state.selected ? "show" : "hide"}`}
      key={id}
    >
      <SliderGallery
        onChange={changeOptionGalleryImg}
        page={state.imgSelected}
        images={img}
        className={[1].includes(state.imgSelected) ? "contain" : ""}
      />
    </div>
  ))
  const titlesSection = state.options.map(({ title: item }, index) => {
    const [shortTitle] = item.split(" ")
    const title = item.split(" ").slice(0, -1).join(" ")
    const subtitle = item.split(" ").slice(-1).join(" ")
    const middleTitle = item.split(" ").slice(1, -1).join(" ")

    return {
      title,
      subtitle,
      shortTitle,
      middleTitle,
    }
  })

  return (
    <section
      id="modalidades"
      className="section-layout bg-green modalities-section"
    >
      <div className="section-layout__content">
        <div className="main-title">
          <h1 className="title">MODALIDADES</h1>
          <p className="subtitle">EXCLUSIVOS DEPARTAMENTOS DE LUJO</p>
        </div>
      </div>
      <div className="section-layout__content">
        <GridComponent position="left">
          <section className="grid-component__title">
            <p className="subtitle">
              {titlesSection[state.selected].shortTitle}
            </p>
            <h1 className="title ">Modalidades</h1>
          </section>
          <section className="grid-component__options">
            <div className="links-button">
              {titlesSection.map((option, index) => {
                return (
                  <>
                    <button
                      className={`link-button txt-complete ${
                        state.selected === index ? "active" : ""
                      }`}
                      key={index}
                      onClick={() => changeOptionGallery(index)}
                    >
                      {option.title}
                    </button>{" "}
                    <button
                      className={`link-button txt-short ${
                        state.selected === index ? "active" : ""
                      }`}
                      key={index}
                      onClick={() => changeOptionGallery(index)}
                    >
                      {option.shortTitle}
                    </button>{" "}
                    {index + 1 < state.options.length && (
                      <p
                        key={`${index}-separator-modalities`}
                        className="line-space "
                      >
                        |
                      </p>
                    )}
                  </>
                )
              })}
            </div>
            <ButtonLinkHeader
              options={state.options}
              optionSelected={state.selected}
              changeOption={changeOptionGallery}
            />
          </section>
          <div className="grid-component__text text-cream">
            <p className="subtitle">EXPLORA TU NUEVO DEPARTAMENTO DE LUJO</p>
            <div className="title-section">
              <h2 className="title">
                {titlesSection[state.selected].middleTitle}
              </h2>
              <h2 className="title">
                {titlesSection[state.selected].subtitle}
              </h2>
            </div>
            <div className="description">
              {state.options[state.selected].text.map((text, index) => (
                <p
                  dangerouslySetInnerHTML={{ __html: text }}
                  className=""
                  key={index}
                />
              ))}
              {state.options[state.selected].link && (
                <div className="button-container">
                  <a href={state.options[state.selected].link} target="_black">
                    <Button border text="Visita tu departamento"></Button>
                  </a>
                </div>
              )}
            </div>

            <div className="options">
              {state.options.map((option, index) => (
                <p className="line-botom " key={index}>
                  <span className="modality">{option.title}</span>
                  <span className="option">{option.subtext}</span>
                </p>
              ))}
            </div>
          </div>
          {state.options[state.selected].link && (
            <div className="button-container">
              <a href={state.options[state.selected].link} target="_black">
                <Button border text="Visita tu departamento"></Button>
              </a>
            </div>
          )}
          <div className="details">
            <p>TU DEPARTAMENTO INCLUYE</p>
            <div className="content">
              {state.options[state.selected].text.map((text, index) => (
                <>
                  <div
                    dangerouslySetInnerHTML={{ __html: text }}
                    className=""
                    key={index}
                  />
                  {index + 1 < state.options[state.selected].text.length && (
                    <p
                      key={`${index}-separator-modalities-button`}
                      className="line-space "
                    >
                      |
                    </p>
                  )}
                </>
              ))}
            </div>
          </div>
          <div className="grid-component__imgs">
            <a
              href={state.options[state.selected].link}
              target="_black"
              className="container__imgs"
            >
              {SliderGalleryList}
            </a>
          </div>
          <div className="grid-component__select">
            {state.options[state.selected].img.length > 1 && (
              <div className="pagination">
                <button
                  className="icon-button link-button arrow "
                  onClick={clickLeft}
                >
                  <LeftArrow className="link-button icon-button" />
                </button>
                <div className="pagination__items">
                  {state.options[state.selected].img.map((_, index) => (
                    <>
                      <button
                        disabled
                        className={
                          "button icon-button " +
                          (state.imgSelected === index ? "text-light" : "")
                        }
                        onClick={() => changeOptionGalleryImg(index + 1)}
                      >
                        {index + 1}
                      </button>
                      {index + 1 < state.options[state.selected].img.length && (
                        <p
                          key={`${index}-separator-modalities-button`}
                          className="line-space line line-small bg-light text-green"
                        >
                          |
                        </p>
                      )}
                    </>
                  ))}
                </div>
                <button className="icon-button  arrow " onClick={clickRight}>
                  <RightArrow className="link-button icon-button" />
                </button>
              </div>
            )}
            <ShowResponsiveMessage drag={2} className="text-green" />
          </div>
        </GridComponent>
      </div>
    </section>
  )
}

export default Modalities
