import Carousel from "@brainhubeu/react-carousel"
import "@brainhubeu/react-carousel/lib/style.css"
import React from "react"
interface Props {
  images?: any[]
  page?: number
  className?: string
  auto?: boolean
  onChange: (id) => void
}

const SliderGallery = (props: Props) => {
  return props.auto ? (
    <Carousel autoPlay={5000} infinite>
      {props.images.map(({ img }, idx) => (
        <img src={img} key={img} height="100%" className={props.className} />
      ))}
    </Carousel>
  ) : (
    <Carousel
      value={props.page}
      onChange={e => {
        return props.onChange(e)
      }}
      keepDirectionWhenDragging
    >
      {props.images.map(({ img }, idx) => (
        <img
          src={img}
          key={idx}
          height="100%"
          loading="eager"
          className={props.className}
        />
      ))}
    </Carousel>
  )
}

export default SliderGallery
