import React, { useState } from "react"
import LeftArrow from "./LeftArrow"
import RightArrow from "./RightArrow"

interface Props {
  options: any[]
  optionSelected: number
  changeOption?: (index) => void
  className?: string
}

const ButtonLinkHeader = (props: Props) => {
  const [selected, setSelected] = useState(props.optionSelected)
  const clickToLeft = () => {
    if (props.optionSelected === 0)
      return props.changeOption(props.options.length - 1)
    if (props.optionSelected - 1 >= 0)
      props.changeOption(props.optionSelected - 1)
  }
  const clickToRight = () => {
    if (props.optionSelected === props.options.length - 1)
      return props.changeOption(0)
    if (props.optionSelected + 1 < props.options.length)
      props.changeOption(props.optionSelected + 1)
  }

  return (
    <div className="buttons-links-header">
      {props.options[props.optionSelected]?.title && (
        <div className="option-link">
          <p>{props.options[props.optionSelected]?.title}</p>
        </div>
      )}
      <div className="buttons-links-pagination">
        <button className="icon-button" onClick={clickToLeft}>
          <LeftArrow className="link-button icon-button" />
        </button>
        <div className="squares-dots">
          {props.options.map((option, index) => (
            <div
              className={
                "square" + (props.optionSelected === index ? " active " : "")
              }
              key={option.title + "-dots-" + index}
            />
          ))}
        </div>
        <button className="icon-button" onClick={clickToRight}>
          <RightArrow className="link-button icon-button" />
        </button>
      </div>
    </div>
  )
}

export default ButtonLinkHeader
