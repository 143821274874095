import React, { ReactChildren, ReactElement } from "react"
import Button from "./Button"
import IconCross from "./IconCross"

interface Props {
  children?: ReactElement | ReactChildren | null[]
  showModal?: Boolean
  closeModal?: VoidFunction
}

const Modal = (props: Props) => {
  return props.showModal ? (
    <section className={`modal  ${props.showModal ? "active-modal" : ""}`}>
      <div className="modal__bg"></div>

      <Button className="modal__close icon-button" onClick={props.closeModal}>
        <div style={{ fontSize: "30px" }}>
          <IconCross />
        </div>
      </Button>
      <div className="modal__content">{props.children}</div>
    </section>
  ) : null
}

export default Modal
